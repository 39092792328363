import { type UserCardPaymentProviderFragment } from '@/controllers/user/graphql/generated/UserCardPaymentProvider.fragment.generated';
import { type UserCardsQueryResult, useUserCardsQuery } from '@/controllers/user/graphql/generated/userCards.query.generated';
import { type UserCardBaseFragment } from '@/controllers/user/graphql/generated/UserCardBase.fragment.generated';
import { EMPTY_ARRAY } from '@/constants';

export type UserCardsHookOutput = [
  (UserCardBaseFragment & UserCardPaymentProviderFragment)[],
  UserCardsQueryResult
];

export const useUserCards = (): UserCardsHookOutput => {
  const queryResult = useUserCardsQuery();

  const content = queryResult.data?.userCards ?? EMPTY_ARRAY;

  return [
    content,
    queryResult,
  ];
};
