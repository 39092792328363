import { EMPTY_OBJECT } from '@/constants';
import { sendAmplitudeData } from '@/controllers/amplitude/amplitude.helpers';
import analytics from '@/controllers/analytics/analytics.client/GoogleAnalytics';

export const AnalyticsEvents = {
  sendEvent: (event: string, data: Record<string, any> = EMPTY_OBJECT) => {
    sendAmplitudeData(event, data);
    analytics.sendEvent(event, data);
  },
  events: {
    courseCTAClicked: 'course_cta_button_clicked',
    signedIn: 'user_signed_in',
    infoSurveySubmitted: 'info_survey_submitted',
    testStarted: 'test_assignment_started', // From preview to test
    testContinued: 'test_assignment_continued',
    testDone: 'test_done',
    supportIconClicked: 'support_icon_clicked',

    loanProviderError: 'loan_provider_error',
    typingSpeedTestCompleted: 'typing_speed_test_completed',
    softDeadlineActivated: 'soft_deadline_activated',
    softDeadlineUpdated: 'soft_deadline_updated',
    softDeadlineDeactivated: 'soft_deadline_deactivated',
    TimeTrackerWorkingTimeTracked: 'time_tracker_working_time_tracked',
    anonymizationSettingUpdated: 'anonymization_setting_updated',
    studyScheduleEnabled: 'study_schedule_enabled',
    studyScheduleDisabled: 'study_schedule_disabled',
    studyScheduleUpdated: 'study_schedule_updated',
  },
};
