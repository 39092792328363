import { type MouseEventHandler, useCallback } from 'react';
import { useApolloClient } from '@apollo/client';
import { useParticipantsContext } from '@/components/platform/Chat/contexts/ParticipantsContext';
import { useUserProfileContext } from '@/components/platform/UserProfile/UserProfileContext';
import { ChatParticipantMinimalDocument, type ChatParticipantMinimalQuery } from '@/components/platform/Chat/graphql/generated/chatParticipantMinimal.query.generated';
import { GroupMentions } from '@/components/ui/ChatInput/ParticipantMention/ParticipantMention.typedefs';
import { UserProfileOpener } from '@/components/platform/UserProfile/typedefs';
import { emptyFunction } from '@/lib/helpers/functional';
import { EMPTY_ARRAY } from '@/constants';

export type OnMentionClick = MouseEventHandler<HTMLAnchorElement> | undefined;

export const useHandleMentionClick = () => {
  const apolloClient = useApolloClient();

  const { participantsMap } = useParticipantsContext();
  const { openProfile } = useUserProfileContext();

  const handleMentionClick = useCallback(async (participantId: number) => {
    const participantFromMap = participantsMap?.get(participantId);

    if (participantFromMap) {
      openProfile({
        userId: participantFromMap.userId,
        opener: UserProfileOpener.ChatMention,
      });

      return;
    }

    openProfile({
      userId: -1,
      opener: UserProfileOpener.ChatMention,
    });

    const { data } = await apolloClient.query<ChatParticipantMinimalQuery>({
      query: ChatParticipantMinimalDocument,
      variables: {
        ids: [participantId],
      },
    });

    const [participant] = data?.participantsByIds ?? EMPTY_ARRAY;

    if (!participant) {
      return;
    }

    openProfile({
      userId: participant.userId,
      opener: UserProfileOpener.ChatMention,
    });
  }, [apolloClient, participantsMap, openProfile]);

  const onMentionClick = useCallback(
    (
      isMention: boolean,
      participantId: string | GroupMentions,
    ): OnMentionClick => {
      if (
        !isMention
        || participantId === GroupMentions.All
        || !participantId
      ) {
        return emptyFunction;
      }

      return (
        () => handleMentionClick(Number(participantId))
      );
    },
    [handleMentionClick],
  );

  return { onMentionClick };
};
