import { type FC } from 'react';
import TimeAgo from 'react-timeago';
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter';
import { WEEK } from '@/constants';
import { useLocaleDateTimeString } from '@/hooks/useLocaleDateTimeString';
import { getLocaleStrings } from '@/components/platform/common/HumanReadableDate/HumanReadableDate.helpers';

interface Props {
  language: string;
  time: number | string | Date;
  isShort?: boolean;
}

export const HumanReadableDate: FC<Props> = ({ language, time, isShort }) => {
  const localizedTimeStrings = getLocaleStrings(language, isShort);
  const formatter = buildFormatter(localizedTimeStrings);
  const timestamp = new Date(time).getTime();

  const formatDateTime = useLocaleDateTimeString();

  if (Date.now() - timestamp >= WEEK) {
    const date = formatDateTime(time);

    return (
      <time
        dateTime={date}
        title={date}
      >
        {date}
      </time>
    );
  }

  return (
    <TimeAgo
      date={time}
      formatter={formatter}
      title={formatDateTime(time)}
    />
  );
};
